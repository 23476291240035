import { AxiosError } from "axios"
import { addHours } from "date-fns"

import {
  IncidentReportDialog,
  IncidentReportDialogProps,
} from "src/components/Reports/IncidentReportDialog"
import { TIncidentReportDownloadInitiatedContext } from "src/data/analytics/queries/ReportAnalyticsQueries"
import {
  TIncidentReportRequestError,
  usePostIncidentReportRequest,
} from "src/data/reports/reportQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { downloadFromUrl } from "src/utils/downloadUtil"
import { ErrorService } from "src/utils/ErrorService"

import { useIncidentReportParams } from "./useIncidentReportParams"

/** Incident report dialog + search params for state */
export function IncidentReportDialogContainer({
  context,
  keepDataOnCancel,
}: {
  context: TIncidentReportDownloadInitiatedContext
  keepDataOnCancel?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const postIncidentReportRequest = usePostIncidentReportRequest()
  const reportParams = useIncidentReportParams()

  function handleConfirm({
    date,
    homeId,
    duration,
  }: Parameters<IncidentReportDialogProps["dialogProps"]["onConfirm"]>[0]) {
    postIncidentReportRequest.mutate(
      {
        start_at: date.toISOString(),
        end_at: addHours(date, duration).toISOString(),
        home_id: homeId,
      },
      {
        onSuccess(data) {
          downloadFromUrl(data.url)
          reportParams.resetDialogFields()
          reportParams.setDialogOpen(false)
        },
        onError(error) {
          const errorKey = error.response?.data.error_key
          switch (errorKey) {
            case "feature_unavailable_for_organization":
            case "invalid_date_range":
              // neither of these should happen; if they do, there's an app logic
              // error and we should report it:
              ErrorService.captureException(new Error(errorKey))
          }
        },
      }
    )
  }

  function handlePotentialError(
    error: AxiosError<TIncidentReportRequestError> | null
  ): string | undefined {
    if (
      reportParams.duration != null &&
      (reportParams.duration > 24 || reportParams.duration < 1)
    ) {
      return t(langKeys.download_incident_report_duration_error)
    }

    if (!error) return // no error

    return t(langKeys.failed_something_went_wrong)
  }

  function handleCancel() {
    !keepDataOnCancel && reportParams.resetDialogFields()
    postIncidentReportRequest.reset()
    reportParams.setDialogOpen(false)
  }

  return (
    <IncidentReportDialog
      context={context}
      reportOptions={reportParams}
      dialogProps={{
        open: !!reportParams.dialogOpen,
        onClose: handleCancel,
        onConfirm: handleConfirm,
        loading: postIncidentReportRequest.isLoading,
        error: handlePotentialError(postIncidentReportRequest.error),
      }}
    />
  )
}
